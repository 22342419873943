import { defineStore } from 'pinia'

export const useRelayPlatformStore = defineStore({
  id: 'relay_platform_onboarding',
  state() {
    return {
      relayPlatformStaticPagesUrl: 'https://relay-commerce.github.io/landing-2024-oct/',
      childUrl: null,
      relayPlatformOnboardingStatus: null,
    }
  },
  actions: {
    setRelayPlatformOnboardingStatus(status) {
      this.relayPlatformOnboardingStatus = status
    },
    async getRelayPlatformUrlForConnection(page) {
      const params = { page: page }
      try {
        const response = await this.$nuxt.$axios.$get(
          `${process.env.API_V1_URL}/relay_platform/connection`,
          { params }
        );

        this.childUrl = response.data.url;
      } catch (error) {
        this.$nuxt.$toasts.add({
          type: 'error',
          title: 'Could not connect!',
          subtitle: `${error.response.data.errors.join(".\n")}`,
          bottomMessage: '',
          error
        })
      }
    },
    async updateRelayOnboardingUserStatus(relayOnboardingStatus) {
      const params = { relay_platform_onboarding_status: relayOnboardingStatus }
      try {
        const response = await this.$nuxt.$axios.$patch(
          `${process.env.API_V1_URL}/shops/current/relay_platform_onboarding_status`,
          { shop: params }
        );

        this.setRelayPlatformOnboardingStatus(relayOnboardingStatus);
      } catch (error) {
        this.$nuxt.$toasts.add({
          type: 'error',
          title: 'Could not save the changes!',
          subtitle: `${error.response.data.errors.join(".\n")}`,
          bottomMessage: '',
          error
        })
      }
    },
  },
  getters: {
    relayPlatformStaticPagesBannerUrl() {
      return this.relayPlatformStaticPagesUrl + 'banner.html'
    },
    isRelayOnboardingStatusApprovedNotStarted() {
      return this.relayPlatformOnboardingStatus === "approved_not_started"
    },
    isRelayOnboardingStatusApprovedLive() {
      return(
        this.relayPlatformOnboardingStatus === 'approved_live'
      )
    },
    isRelayOnboardingStatusNotLive(){
      return !this.isRelayOnboardingStatusApprovedLive
    },
    isRelayOnboardingStatusUnapprovedOffered() {
      return(
        this.relayPlatformOnboardingStatus === 'unapproved_offered'
      )
    }
  },
})
