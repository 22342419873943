import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0821fedc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _686ff130 = () => interopDefault(import('../pages/boost-sales.vue' /* webpackChunkName: "pages/boost-sales" */))
const _103edbcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _6686aaf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _62b341cc = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _106b822f = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _1ad4ee76 = () => interopDefault(import('../pages/profile/invoices.vue' /* webpackChunkName: "pages/profile/invoices" */))
const _5866752d = () => interopDefault(import('../pages/profile/subscription.vue' /* webpackChunkName: "pages/profile/subscription" */))
const _1f88bcf9 = () => interopDefault(import('../pages/relay-persona/index.vue' /* webpackChunkName: "pages/relay-persona/index" */))
const _133bf5c2 = () => interopDefault(import('../pages/review.vue' /* webpackChunkName: "pages/review" */))
const _1f215d6d = () => interopDefault(import('../pages/smartrmail/index.vue' /* webpackChunkName: "pages/smartrmail/index" */))
const _5b22d2e8 = () => interopDefault(import('../pages/pops/events.vue' /* webpackChunkName: "pages/pops/events" */))
const _48abe440 = () => interopDefault(import('../pages/pops/metrics.vue' /* webpackChunkName: "pages/pops/metrics" */))
const _bfee2936 = () => interopDefault(import('../pages/pops/rules-and-settings/index.vue' /* webpackChunkName: "pages/pops/rules-and-settings/index" */))
const _0517b516 = () => interopDefault(import('../pages/relay-persona/audiences.vue' /* webpackChunkName: "pages/relay-persona/audiences" */))
const _8673b4ec = () => interopDefault(import('../pages/relay-persona/campaigns.vue' /* webpackChunkName: "pages/relay-persona/campaigns" */))
const _d668d50e = () => interopDefault(import('../pages/relay-persona/channels.vue' /* webpackChunkName: "pages/relay-persona/channels" */))
const _7d6db78a = () => interopDefault(import('../pages/relay-persona/workflows.vue' /* webpackChunkName: "pages/relay-persona/workflows" */))
const _74b11ca1 = () => interopDefault(import('../pages/smartrmail/analytics.vue' /* webpackChunkName: "pages/smartrmail/analytics" */))
const _501a6f97 = () => interopDefault(import('../pages/smartrmail/automations.vue' /* webpackChunkName: "pages/smartrmail/automations" */))
const _34d6a0aa = () => interopDefault(import('../pages/smartrmail/brand-kit.vue' /* webpackChunkName: "pages/smartrmail/brand-kit" */))
const _c9a996a2 = () => interopDefault(import('../pages/smartrmail/dashboard.vue' /* webpackChunkName: "pages/smartrmail/dashboard" */))
const _5574f535 = () => interopDefault(import('../pages/smartrmail/newsletters.vue' /* webpackChunkName: "pages/smartrmail/newsletters" */))
const _2ee14752 = () => interopDefault(import('../pages/smartrmail/product-feeds.vue' /* webpackChunkName: "pages/smartrmail/product-feeds" */))
const _4f882432 = () => interopDefault(import('../pages/smartrmail/profiles-lists.vue' /* webpackChunkName: "pages/smartrmail/profiles-lists" */))
const _0151a019 = () => interopDefault(import('../pages/smartrmail/smart-segments.vue' /* webpackChunkName: "pages/smartrmail/smart-segments" */))
const _34158998 = () => interopDefault(import('../pages/smartrmail/sms.vue' /* webpackChunkName: "pages/smartrmail/sms" */))
const _89af9a58 = () => interopDefault(import('../pages/smartrmail/templates.vue' /* webpackChunkName: "pages/smartrmail/templates" */))
const _8c05aa9e = () => interopDefault(import('../pages/upsells/history.vue' /* webpackChunkName: "pages/upsells/history" */))
const _3e7e5760 = () => interopDefault(import('../pages/upsells/metrics.vue' /* webpackChunkName: "pages/upsells/metrics" */))
const _356abbbb = () => interopDefault(import('../pages/pops/rules-and-settings/design.vue' /* webpackChunkName: "pages/pops/rules-and-settings/design" */))
const _43f7c065 = () => interopDefault(import('../pages/pops/rules-and-settings/discounts.vue' /* webpackChunkName: "pages/pops/rules-and-settings/discounts" */))
const _3683048a = () => interopDefault(import('../pages/pops/rules-and-settings/general.vue' /* webpackChunkName: "pages/pops/rules-and-settings/general" */))
const _f1f7033a = () => interopDefault(import('../pages/pops/rules-and-settings/media-templates.vue' /* webpackChunkName: "pages/pops/rules-and-settings/media-templates" */))
const _05e4e957 = () => interopDefault(import('../pages/pops/rules-and-settings/pages.vue' /* webpackChunkName: "pages/pops/rules-and-settings/pages" */))
const _0276976e = () => interopDefault(import('../pages/pops/rules-and-settings/reviews.vue' /* webpackChunkName: "pages/pops/rules-and-settings/reviews" */))
const _7d09f660 = () => interopDefault(import('../pages/pops/rules-and-settings/settings.vue' /* webpackChunkName: "pages/pops/rules-and-settings/settings" */))
const _58f457c7 = () => interopDefault(import('../pages/pops/rules-and-settings/themes.vue' /* webpackChunkName: "pages/pops/rules-and-settings/themes" */))
const _aa5b16c8 = () => interopDefault(import('../pages/upsells/rules-and-settings/add-to-cart/index.vue' /* webpackChunkName: "pages/upsells/rules-and-settings/add-to-cart/index" */))
const _7f12778a = () => interopDefault(import('../pages/upsells/rules-and-settings/general.vue' /* webpackChunkName: "pages/upsells/rules-and-settings/general" */))
const _f85b4d38 = () => interopDefault(import('../pages/upsells/rules-and-settings/add-to-cart/design.vue' /* webpackChunkName: "pages/upsells/rules-and-settings/add-to-cart/design" */))
const _4fdbd61c = () => interopDefault(import('../pages/upsells/rules-and-settings/add-to-cart/discounts.vue' /* webpackChunkName: "pages/upsells/rules-and-settings/add-to-cart/discounts" */))
const _5ffe646e = () => interopDefault(import('../pages/upsells/rules-and-settings/add-to-cart/settings.vue' /* webpackChunkName: "pages/upsells/rules-and-settings/add-to-cart/settings" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _0821fedc,
    name: "auth"
  }, {
    path: "/boost-sales",
    component: _686ff130,
    name: "boost-sales"
  }, {
    path: "/dashboard",
    component: _103edbcc,
    name: "dashboard"
  }, {
    path: "/logout",
    component: _6686aaf4,
    name: "logout"
  }, {
    path: "/pricing",
    component: _62b341cc,
    name: "pricing"
  }, {
    path: "/profile",
    component: _106b822f,
    name: "profile",
    children: [{
      path: "invoices",
      component: _1ad4ee76,
      name: "profile-invoices"
    }, {
      path: "subscription",
      component: _5866752d,
      name: "profile-subscription"
    }]
  }, {
    path: "/relay-persona",
    component: _1f88bcf9,
    name: "relay-persona"
  }, {
    path: "/review",
    component: _133bf5c2,
    name: "review"
  }, {
    path: "/smartrmail",
    component: _1f215d6d,
    name: "smartrmail"
  }, {
    path: "/pops/events",
    component: _5b22d2e8,
    name: "pops-events"
  }, {
    path: "/pops/metrics",
    component: _48abe440,
    name: "pops-metrics"
  }, {
    path: "/pops/rules-and-settings",
    component: _bfee2936,
    name: "pops-rules-and-settings"
  }, {
    path: "/relay-persona/audiences",
    component: _0517b516,
    name: "relay-persona-audiences"
  }, {
    path: "/relay-persona/campaigns",
    component: _8673b4ec,
    name: "relay-persona-campaigns"
  }, {
    path: "/relay-persona/channels",
    component: _d668d50e,
    name: "relay-persona-channels"
  }, {
    path: "/relay-persona/workflows",
    component: _7d6db78a,
    name: "relay-persona-workflows"
  }, {
    path: "/smartrmail/analytics",
    component: _74b11ca1,
    name: "smartrmail-analytics"
  }, {
    path: "/smartrmail/automations",
    component: _501a6f97,
    name: "smartrmail-automations"
  }, {
    path: "/smartrmail/brand-kit",
    component: _34d6a0aa,
    name: "smartrmail-brand-kit"
  }, {
    path: "/smartrmail/dashboard",
    component: _c9a996a2,
    name: "smartrmail-dashboard"
  }, {
    path: "/smartrmail/newsletters",
    component: _5574f535,
    name: "smartrmail-newsletters"
  }, {
    path: "/smartrmail/product-feeds",
    component: _2ee14752,
    name: "smartrmail-product-feeds"
  }, {
    path: "/smartrmail/profiles-lists",
    component: _4f882432,
    name: "smartrmail-profiles-lists"
  }, {
    path: "/smartrmail/smart-segments",
    component: _0151a019,
    name: "smartrmail-smart-segments"
  }, {
    path: "/smartrmail/sms",
    component: _34158998,
    name: "smartrmail-sms"
  }, {
    path: "/smartrmail/templates",
    component: _89af9a58,
    name: "smartrmail-templates"
  }, {
    path: "/upsells/history",
    component: _8c05aa9e,
    name: "upsells-history"
  }, {
    path: "/upsells/metrics",
    component: _3e7e5760,
    name: "upsells-metrics"
  }, {
    path: "/pops/rules-and-settings/design",
    component: _356abbbb,
    name: "pops-rules-and-settings-design"
  }, {
    path: "/pops/rules-and-settings/discounts",
    component: _43f7c065,
    name: "pops-rules-and-settings-discounts"
  }, {
    path: "/pops/rules-and-settings/general",
    component: _3683048a,
    name: "pops-rules-and-settings-general"
  }, {
    path: "/pops/rules-and-settings/media-templates",
    component: _f1f7033a,
    name: "pops-rules-and-settings-media-templates"
  }, {
    path: "/pops/rules-and-settings/pages",
    component: _05e4e957,
    name: "pops-rules-and-settings-pages"
  }, {
    path: "/pops/rules-and-settings/reviews",
    component: _0276976e,
    name: "pops-rules-and-settings-reviews"
  }, {
    path: "/pops/rules-and-settings/settings",
    component: _7d09f660,
    name: "pops-rules-and-settings-settings"
  }, {
    path: "/pops/rules-and-settings/themes",
    component: _58f457c7,
    name: "pops-rules-and-settings-themes"
  }, {
    path: "/upsells/rules-and-settings/add-to-cart",
    component: _aa5b16c8,
    name: "upsells-rules-and-settings-add-to-cart"
  }, {
    path: "/upsells/rules-and-settings/general",
    component: _7f12778a,
    name: "upsells-rules-and-settings-general"
  }, {
    path: "/upsells/rules-and-settings/add-to-cart/design",
    component: _f85b4d38,
    name: "upsells-rules-and-settings-add-to-cart-design"
  }, {
    path: "/upsells/rules-and-settings/add-to-cart/discounts",
    component: _4fdbd61c,
    name: "upsells-rules-and-settings-add-to-cart-discounts"
  }, {
    path: "/upsells/rules-and-settings/add-to-cart/settings",
    component: _5ffe646e,
    name: "upsells-rules-and-settings-add-to-cart-settings"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
