import { useUserStore } from '~/store/user';
import { useUiStore } from '~/store/ui';
import { useSiteStore } from '~/store/site';
import { useBaseOnboardingStore } from '~/store/onboardings/baseOnboarding';
import { usePopMetricsStore } from "~/store/pops/metrics";
import { useRelayPlatformStore } from "~/store/relayPlatform";
import moment from 'moment';

export default ({ route }, _inject) => {
  const query = route?.query || {}
  const userStore = useUserStore()

  const preflight = () => {
    const siteStore = useSiteStore();
    const uiStore = useUiStore();
    const onboardingStore = useBaseOnboardingStore();
    const popMetricsStore = usePopMetricsStore();
    const relayPlatformStore = useRelayPlatformStore();

    // Load data required for correct application startup.
    Promise.all([
      siteStore.loadCurrentSite(),
      onboardingStore.loadShopOnboardings(),
      popMetricsStore.loadOverviewConversionsTotals(
        {
          startDate: moment().startOf('month'),
          endDate: moment()
        }
      ),
    ]).then(() => {
      uiStore.loadingPreflight = false
      siteStore.initializeExternalTools()
      relayPlatformStore.setRelayPlatformOnboardingStatus(siteStore.currentSite.relay_platform_onboarding_status)
    }).catch((e) => {
      console.error(e)
      window.top.location.assign(`${process.env.BASE_BACKEND_URL}`)
    })
  }

  if(query.embedded === '1' || userStore.authToken || userStore.adminToken) {
    preflight()
  }
}
